import gql from 'graphql-tag';

export default gql`
  mutation updateNote($noteId: String!, $content: String!, $attachment: S3ObjectInput) {
    updateNote(noteId: $noteId, content: $content, attachment: $attachment) {
      __typename
      noteId
      userId
      content
      attachment {
        region
        bucket
        key
      }
      createdAt
    }
  }
`;
