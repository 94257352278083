import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, List, ListItem, ListItemText } from '@material-ui/core';
import { Query } from 'react-apollo';
import { getNotes } from '../graphql';
import styled from 'styled-components/macro';

const StyledHome = styled.div`
  .lander {
    padding: 80px 0;
    text-align: center;
  }

  .lander h1 {
    font-weight: 600;
  }

  .lander div {
    padding-top: 20px;
  }
  .lander div a:first-child {
    margin-right: 20px;
  }

  .page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eee;
  }

  .list-item {
    background-color: #fff;
    border: 1px solid #ddd;
    margin-bottom: -1px;
  }

  .list-item-new {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    this.setState({ isLoading: false });
  }

  handleNoteClick = event => {
    event.preventDefault();
    this.props.history.push(event.currentTarget.getAttribute('href'));
  };

  renderLander() {
    return (
      <div className="lander">
        <Typography variant="h3" color="textPrimary">Scratch</Typography>
        <Typography variant="subtitle1" color="textSecondary">A simple note taking app</Typography>
        <div>
          <Button component={Link} variant="contained" color="primary" size="large" to="/login">Login</Button>
          <Button component={Link} variant="contained" color="secondary" size="large" to="/signup">Signup</Button>
        </div>
      </div>
    );
  }

  renderNotes() {
    return (
      <div className="notes">
        <Typography className='page-header' variant="h3">Your Notes</Typography>
        <List component='nav' className='list'>
          {!this.state.isLoading && (
            <Query
              query={getNotes}
              fetchPolicy='cache-and-network'
            >
              {({ loading, error, data }) => {
                if (loading) return 'Loading...';
                if (error) return `Error! ${error.message}`;

                return [{}].concat(data.getNotes).map(
                  (note, i) =>
                    i !== 0 ? (
                      <ListItem
                        button
                        className='list-item'
                        key={note.noteId}
                        href={`/notes/${note.noteId}`}
                        onClick={this.handleNoteClick}
                      >
                        <ListItemText
                          primary={note.content.trim().split('\n')[0]}
                          secondary={`Created: ${new Date(note.createdAt).toLocaleString()}`}
                        />
                      </ListItem>
                    ) : (
                        <ListItem
                          button
                          className='list-item'
                          key="new"
                          href="/notes/new"
                          onClick={this.handleNoteClick}
                        >
                          <ListItemText
                            className='list-item-new'
                            primary={`${'\uFF0B'} Create a new note`}
                          />
                        </ListItem>
                      )
                );
              }}
            </Query>
          )}
        </List>
      </div>
    );
  }

  render() {
    return (
      <StyledHome className="Home">
        {this.props.isAuthenticated ? this.renderNotes() : this.renderLander()}
      </StyledHome>
    );
  }
}
