import gql from 'graphql-tag';

export default gql`
  query getNotes {
    getNotes {
      __typename
      noteId
      userId
      content
      attachment {
        region
        bucket
        key
      }
      createdAt
    }
  }
`;
