const dev = {
  STRIPE_KEY: 'pk_test_9CtBwYKV19RXIl5jwelcBR85',
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'rankfrank-api-dev-attachmentsbucket-1atxhlvsz49gv',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_JWAYDV6LK',
    APP_CLIENT_ID: '2ic2oph9dqc3umc86l8dac3cb',
    IDENTITY_POOL_ID: 'us-east-1:d09dc1b6-19be-462c-b049-c1b393e72b81',
  },
  appSync: {
    URL: 'https://gqutllcvxndsjhn5uvy5edees4.appsync-api.us-east-1.amazonaws.com/graphql',
    REGION: 'us-east-1',
    AUTH_TYPE: 'AWS_IAM',
  },
};

const prod = {
  STRIPE_KEY: 'pk_test_9CtBwYKV19RXIl5jwelcBR85',
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'rankfrank-api-prod-attachmentsbucket-1p25gen4s2mwz',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_XiaoTigSg',
    APP_CLIENT_ID: '2octivt9oa5elbm18elku1n796',
    IDENTITY_POOL_ID: 'us-east-1:1f2c7d4d-590d-4501-a006-67b6b0101e0f',
  },
  appSync: {
    URL: '',
    REGION: 'us-east-1',
    AUTH_TYPE: 'AWS_IAM',
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
