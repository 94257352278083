import React from 'react';
import { Autorenew } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import styled, { keyframes } from 'styled-components/macro';

const spin = keyframes`
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
`;

const LoaderButton = styled(Button)`
  && {
    &.wide-button {
      width: 100%;
    }
    svg {
      margin-right: 7px;
    }
    svg.spinning {
      animation: ${spin} 1s infinite linear;
    }
  }
`;

export default ({ isLoading, text, loadingText, className = '', disabled = false, ...props }) => (
  <LoaderButton className={`LoaderButton ${className}`} disabled={disabled || isLoading} {...props}>
    {isLoading && <Autorenew className="spinning" />}
    {!isLoading ? text : loadingText}
  </LoaderButton>
);
