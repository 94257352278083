import React, { Component } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { Mutation } from 'react-apollo';
import uuid from 'uuid/v4';
import BillingForm from '../components/BillingForm';
import { createOrder } from '../graphql';
import config from '../config';
import styled from 'styled-components/macro';

const StyledSettings = styled.div`
  @media all and (min-width: 480px) {
    padding: 60px 0;
    form {
      margin: 0 auto;
      max-width: 480px;
    }
  }
`
export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  handleOrder = async (createOrder, storage, { token, error }) => {
    if (error) {
      alert(error);
      return;
    }

    this.setState({ isLoading: true });

    try {
      await createOrder({
        variables: { storage, stripeToken: token.id },
        optimisticResponse: {
          __typename: "Mutation",
          createOrder: {
            __typename: "Order",
            orderId: uuid(),
            userId: "UNKNOWN_USERID",
            storage: storage,
            stripeToken: token.id,
            amount: 0,
            isPaid: false,
            createdAt: new Date(),
          }
        }
      });

      alert('Your card has been charged successfully!');
      this.props.history.push('/');
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <StyledSettings className="Settings">
        <StripeProvider apiKey={config.STRIPE_KEY}>
          <Elements>
            <Mutation mutation={createOrder}>
              {createOrder => (
                <BillingForm
                  loading={this.state.isLoading}
                  onSubmit={(storage, { token, error }) =>
                    this.handleOrder(createOrder, storage, { token, error })
                  }
                />
              )}
            </Mutation>
          </Elements>
        </StripeProvider>
      </StyledSettings>
    );
  }
}
