import React, { Component } from 'react';
import Auth from '@aws-amplify/auth';
import { TextField, Typography, Button } from '@material-ui/core';
import { Mutation } from 'react-apollo';
import uuid from 'uuid/v4';
import LoaderButton from '../components/LoaderButton';
import config from '../config';
import { createNote, getNotes } from '../graphql';
import styled from 'styled-components/macro';

const StyledNewNote = styled.div`
  form {
    padding-bottom: 15px;
  }
  .text-area-input {
    font-size: 24px;
  }
  .text-area {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px 12px;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-group > * {
    margin-bottom: 5px;
  }
  .file-input {
    display: none;
  }
  .file-text {
    display: inline;
    margin-left: 15px;
  }
`

export default class NewNote extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      content: '',
    };
  }

  validateForm() {
    return this.state.content.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleFileChange = event => {
    this.file = event.target.files[0];
  };

  handleCreate = async (mutate, event) => {
    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000} MB.`);
      return;
    }

    this.setState({ isLoading: true });

    const bucket = config.s3.BUCKET;
    const region = config.s3.REGION;

    const visibility = 'private';

    const selectedFile = this.file;

    const { id: identityId } = await Auth.currentUserInfo();

    let attachment;
    let attachmentUI;

    if (selectedFile) {
      const { name: fileName, type: mimeType } = selectedFile;
      const key = `${visibility}/${identityId}/${Date.now()}-${fileName}`;

      attachment = {
        localUri: selectedFile,
        mimeType,
        region,
        bucket,
        key,
      };

      attachmentUI = {
        __typename: "S3Object",
        region,
        bucket,
        key,
      };
    }

    try {
      await mutate({
        variables: { content: this.state.content, attachment },
        optimisticResponse: {
          __typename: "Mutation",
          createNote: {
            __typename: "Note",
            noteId: uuid(),
            userId: "UNKNOWN_USERID",
            content: this.state.content,
            attachment: attachmentUI ? attachmentUI : null,
            createdAt: new Date()
          }
        }
      });

      this.props.history.push('/');
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  };

  updateCreateNote = (
    cache,
    { data: { createNote } },
  ) => {
    const query = getNotes;
    const cacheNotes = cache.readQuery({ query });
    cache.writeQuery({
      query,
      data: { getNotes: cacheNotes.getNotes.concat(createNote) },
    });
  };

  render() {
    return (
      <StyledNewNote className="NewNote">
        <form>
          <TextField
            className="text-area form-group"
            id="content"
            onChange={this.handleChange}
            value={this.state.content}
            multiline
            rows="10"
            fullWidth
            InputProps={{
              disableUnderline: true,
              className: "text-area-input"
            }}
          />
          <div className='form-group inline-group'>
            <Typography variant="h6">Attachment</Typography>
            <Button
              component="label"
              color="primary"
              variant="outlined"
            >
              Choose File
                    <input
                className='file-input'
                id="file-input"
                type="file"
                onChange={this.handleFileChange}
              />
            </Button>
            <Typography className='file-text' variant="body2">{this.file ? this.file.name : 'No File Chosen'}</Typography>
          </div>
          <div className='form-group'>
            <Mutation
              mutation={createNote}
              update={this.updateCreateNote}
            >
              {mutate => (
                <LoaderButton
                  className="wide-button"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={!this.validateForm()}
                  type="submit"
                  isLoading={this.state.isLoading}
                  onClick={e => this.handleCreate(mutate, e)}
                  text="Create"
                  loadingText="Creating…"
                />
              )}
            </Mutation>
          </div>
        </form>
      </StyledNewNote >
    );
  }
}
