import React from 'react';
import styled from 'styled-components/macro';

const StyledNotFound = styled.div`
  padding-top: 100px;
  text-align: center;
`;

export default () => (
  <StyledNotFound className="NotFound">
    <h3>Sorry, page not found!</h3>
  </StyledNotFound>
);
