import React, { Component } from 'react';
import Auth from '@aws-amplify/auth';
import { TextField } from '@material-ui/core';
import LoaderButton from '../components/LoaderButton';
import styled from 'styled-components/macro';

const StyledSignup = styled.div`
  @media all and (min-width: 480px) {
    padding: 60px 0;
    form {
      margin: 0 auto;
      max-width: 320px;
    }  
    form span.help-block {
      font-size: 14px;
      padding-bottom: 10px;
      color: #999;
    }    
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-group > * {
    margin-bottom: 5px;
  }
`

export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: '',
      password: '',
      confirmPassword: '',
      confirmationCode: '',
      newUser: null,
    };
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password,
      });
      this.setState({
        newUser,
      });
    } catch (e) {
      alert(e.message);
    }

    this.setState({ isLoading: false });
  };

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      await Auth.signIn(this.state.email, this.state.password);

      this.props.userHasAuthenticated(true);
      this.props.history.push('/');
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmationSubmit}>
        <TextField
          autoFocus
          fullWidth
          id="confirmationCode"
          className="form-group"
          label="Confirmation Code"
          helperText="Please check your email for the code."
          type="tel"
          value={this.state.confirmationCode}
          onChange={this.handleChange}
        />
        <LoaderButton
          className="wide-button"
          variant="contained"
          color="primary"
          size="large"
          disabled={!this.validateConfirmationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </form>
    );
  }

  renderForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
          autoFocus
          fullWidth
          id="email"
          className="form-group"
          label="Email"
          type="email"
          autoComplete="username"
          value={this.state.email}
          onChange={this.handleChange}
        />
        <TextField
          fullWidth
          id="password"
          className="form-group"
          label="Password"
          type="password"
          autoComplete="new-password"
          value={this.state.password}
          onChange={this.handleChange}
        />
        <TextField
          fullWidth
          id="confirmPassword"
          className="form-group"
          label="Confirm Password"
          type="password"
          autoComplete="new-password"
          value={this.state.confirmPassword}
          onChange={this.handleChange}
        />
        <LoaderButton
          className="wide-button"
          variant="contained"
          color="primary"
          size="large"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Signup"
          loadingText="Signing up…"
        />
      </form>
    );
  }

  render() {
    return (
      <StyledSignup className="Signup">
        {this.state.newUser === null ? this.renderForm() : this.renderConfirmationForm()}
      </StyledSignup>
    );
  }
}
