import gql from 'graphql-tag';

export default gql`
  mutation createOrder($storage: Int!, $stripeToken: String!) {
    createOrder(storage: $storage, stripeToken: $stripeToken) {
      __typename
      orderId
      userId
      storage
      stripeToken
      amount
      isPaid
      createdAt
    }
  }
`;
