import React, { Component } from 'react';
import Auth from '@aws-amplify/auth';
import Storage from '@aws-amplify/storage';
import { TextField, Typography, Button } from '@material-ui/core';
import { Query, Mutation } from 'react-apollo';
import uuid from 'uuid/v4';
import LoaderButton from '../components/LoaderButton';
import config from '../config';
import { getNote, getNotes, updateNote, deleteNote } from '../graphql';
import styled from 'styled-components/macro';

const StyledNotes = styled.div`
  form {
    padding-bottom: 15px;
  }
  .text-area-input {
    font-size: 24px;
  }
  .text-area {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px 12px;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-group > * {
    margin-bottom: 5px;
  }
  .file-input {
    display: none;
  }
  .file-text {
    display: inline;
    margin-left: 15px;
  }
`

export default class Notes extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      isDeleting: null,
      content: '',
      attachmentURL: null,
      attachmentFile: null,
      attachmentLocalName: null,
      fileLocalName: null,
    };
  }

  async getAttachmentInfo(attachment) {
    try {
      let attachmentURL;
      let attachmentFile;
      let attachmentLocalName;

      if (attachment !== null) {
        const [, , fileName] = /([^/]+\/){2}(.*)$/.exec(attachment.key);
        attachmentFile = fileName;
        attachmentLocalName = fileName.replace(/\d+-/, '');
        attachmentURL = await Storage.get(fileName, { level: 'private' });
      }
      this.setState({
        attachmentURL,
        attachmentFile,
        attachmentLocalName,
      });
    } catch (e) {
      alert(e);
    }
  }

  validateForm() {
    return this.state.content.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleFileChange = event => {
    this.file = event.target.files[0];
    this.setState({
      fileLocalName: this.file.name,
    });
  };

  handleSave = async (updateNote, event) => {
    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000} MB.`);
      return;
    }

    this.setState({ isLoading: true });

    const bucket = config.s3.BUCKET;
    const region = config.s3.REGION;

    const visibility = 'private';

    const selectedFile = this.file;

    const { id: identityId } = await Auth.currentUserInfo();

    let attachment;
    let attachmentUI;

    if (selectedFile) {
      const { name: fileName, type: mimeType } = selectedFile;
      const key = `${visibility}/${identityId}/${Date.now()}-${fileName}`;

      attachment = {
        localUri: selectedFile,
        mimeType,
        region,
        bucket,
        key,
      };

      attachmentUI = {
        __typename: "S3Object",
        region,
        bucket,
        key,
      };
    }

    try {
      await Storage.remove(this.state.attachmentFile, { level: 'private' });
      await updateNote({
        variables: { noteId: this.props.match.params.id, content: this.state.content, attachment },
        optimisticResponse: {
          __typename: "Mutation",
          updateNote: {
            __typename: "Note",
            noteId: this.props.match.params.id,
            userId: uuid(),
            content: this.state.content,
            attachment: attachmentUI ? attachmentUI : null,
            createdAt: new Date()
          }
        }
      });

      this.props.history.push('/');
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  };

  handleDelete = async (deleteNote, event) => {
    event.preventDefault();

    const confirmed = window.confirm('Are you sure you want to delete this note?');

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await Storage.remove(this.state.attachmentFile, { level: 'private' });
      await deleteNote({
        variables: { noteId: this.props.match.params.id },
        optimisticResponse: {
          __typename: "Mutation",
          deleteNote: {
            __typename: "Note",
            noteId: this.props.match.params.id,
            userId: uuid(),
            content: "UNKNOWN_CONTENT",
            attachment: null,
            createdAt: new Date()
          }
        }
      });
      this.props.history.push('/');
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  };

  updateUpdateNote = (
    cache,
    { data: { updateNote } },
  ) => {
    const query = getNotes;
    const cacheNotes = cache.readQuery({ query });
    cache.writeQuery({
      query,
      data: {
        getNotes: cacheNotes.getNotes.map(
          note => note.noteId !== updateNote.noteId ? note : { ...updateNote }
        )
      },
    });
  };

  updateDeleteNote = (
    cache,
    { data: { deleteNote } },
  ) => {
    const query = getNotes;
    const cacheNotes = cache.readQuery({ query });
    cache.writeQuery({
      query,
      data: {
        getNotes: cacheNotes.getNotes.filter(
          note => note.noteId !== deleteNote.noteId
        ),
      },
    });
  };

  render() {
    return (
      <StyledNotes className="Notes">
        <Query
          query={getNote}
          variables={{ noteId: this.props.match.params.id }}
          fetchPolicy='cache-and-network'
          onCompleted={data => {
            this.setState({ content: data.getNote.content });
            this.getAttachmentInfo(data.getNote.attachment);
          }}
        >
          {({ loading, error, data }) => {
            if (loading) return 'Loading...';
            if (error) return `Error! ${error.message}`;

            return (
              <form>
                <TextField
                  className="text-area form-group"
                  id="content"
                  onChange={this.handleChange}
                  value={this.state.content}
                  multiline
                  rows="10"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    className: "text-area-input"
                  }}
                />
                {data.getNote.attachment !== null && (
                  <div className='form-group'>
                    <Typography variant="h6">Attachment</Typography>
                    <a target="_blank" rel="noopener noreferrer" href={this.state.attachmentURL}>
                      {this.state.attachmentLocalName}
                    </a>
                  </div>
                )}
                <div className='form-group inline-group'>
                  {data.getNote.attachment === null && <Typography variant="h6">Attachment</Typography>}
                  <Button
                    component="label"
                    color="primary"
                    variant="outlined"
                  >
                    Choose File
                    <input
                      className='file-input'
                      id="file-input"
                      type="file"
                      onChange={this.handleFileChange}
                    />
                  </Button>
                  <Typography className='file-text' variant="body2">{this.file ? this.state.fileLocalName : 'No File Chosen'}</Typography>
                </div>
                <div className='form-group'>
                  <Mutation
                    mutation={updateNote}
                    update={this.updateUpdateNote}
                  >
                    {updateNote => (
                      <LoaderButton
                        className="wide-button"
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!this.validateForm()}
                        isLoading={this.state.isLoading}
                        onClick={e => this.handleSave(updateNote, e)}
                        text="Save"
                        loadingText="Saving…"
                      />
                    )}
                  </Mutation>
                  <Mutation
                    mutation={deleteNote}
                    update={this.updateDeleteNote}
                  >
                    {deleteNote => (
                      <LoaderButton
                        className="wide-button"
                        variant="contained"
                        color="secondary"
                        size="large"
                        isLoading={this.state.isDeleting}
                        onClick={e => this.handleDelete(deleteNote, e)}
                        text="Delete"
                        loadingText="Deleting…"
                      />
                    )}
                  </Mutation>
                </div>
              </form>
            );
          }}
        </Query>
      </StyledNotes >
    );
  }
}
