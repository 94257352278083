import React, { Component } from 'react';
import Auth from '@aws-amplify/auth';
import { TextField } from '@material-ui/core';
import LoaderButton from '../components/LoaderButton';
import styled from 'styled-components/macro';

const StyledLogin = styled.div`
  @media all and (min-width: 480px) {
    padding: 60px 0;
    form {
      margin: 0 auto;
      max-width: 320px;
    }    
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-group > * {
    margin-bottom: 5px;
  }
`

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: '',
      password: '',
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.signIn(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <StyledLogin className="Login">
        <form onSubmit={this.handleSubmit}>
          <TextField
            autoFocus
            fullWidth
            id="email"
            className="form-group"
            label="Email"
            type="email"
            autoComplete="username"
            value={this.state.email}
            onChange={this.handleChange}
          />
          <TextField
            fullWidth
            id="password"
            className="form-group"
            label="Password"
            type="password"
            autoComplete="current-password"
            value={this.state.password}
            onChange={this.handleChange}
          />
          <LoaderButton
            className="wide-button"
            variant="contained"
            color="primary"
            size="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Login"
            loadingText="Logging in…"
          />
        </form>
      </StyledLogin>
    );
  }
}
