import React, { Component, Fragment } from "react";
import Auth from "@aws-amplify/auth";
import AWSAppSyncClient, { defaultDataIdFromObject } from "aws-appsync";
import { Rehydrated } from "aws-appsync-react";
import { ApolloProvider } from "react-apollo";
import { Link, withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import { AppBar, Toolbar, Typography, Button } from "@material-ui/core";
import styled from "styled-components/macro";
import config from "./config";
import Routes from "./Routes";

const client = new AWSAppSyncClient({
  url: config.appSync.URL,
  region: config.appSync.REGION,
  auth: {
    type: config.appSync.AUTH_TYPE,
    credentials: () => Auth.currentCredentials()
  },
  complexObjectsCredentials: () => Auth.currentCredentials(),
  disableOffline: false,
  cacheOptions: {
    dataIdFromObject: object => {
      if (object.__typename === "Note") console.log(`Note:${object.noteId}`);
      switch (object.__typename) {
        case "Note":
          return `Note:${object.noteId}`;
        case "Order":
          return `Order:${object.orderId}`;
        default:
          return defaultDataIdFromObject(object);
      }
    }
    // cacheRedirects: {
    //   Query: {
    //     getNote: (_, args, { getCacheKey }) => {
    //       console.log("Redirecting");
    //       getCacheKey({ __typename: 'Note', noteId: args.noteId });
    //     }
    //   },
    // },
  }
});

const StyledApp = styled.div`
  margin-top: 15px;
  &.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (min-width: 768px) {
    &.container {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    &.container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    &.container {
      width: 1170px;
    }
  }
  .app-bar {
    margin-bottom: 20px;
  }
  .title {
    flex-grow: 1;
  }
  .title-link {
    text-decoration: none;
    color: inherit;
  }
`;
const theme = createMuiTheme({
  palette: {
    primary: blue
  },
  typography: {
    useNextVariants: true
  }
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }

  async componentDidMount() {
    try {
      if (await Auth.currentSession()) {
        this.userHasAuthenticated(true);
      }
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  handleLogout = async event => {
    await Auth.signOut();

    this.userHasAuthenticated(false);

    this.props.history.push("/login");
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      !this.state.isAuthenticating && (
        <StyledApp className="App container">
          <AppBar className="app-bar" position="static">
            <Toolbar>
              <Typography className="title" variant="h6" color="inherit">
                <Link className="title-link" to="/">
                  Scratch
                </Link>
              </Typography>
              {this.state.isAuthenticated ? (
                <Fragment>
                  <Button component={Link} to="/settings" color="inherit">
                    Settings
                  </Button>
                  <Button onClick={this.handleLogout} color="inherit">
                    Logout
                  </Button>
                </Fragment>
              ) : (
                <Fragment>
                  <Button component={Link} to="/signup" color="inherit">
                    Signup
                  </Button>
                  <Button component={Link} to="/login" color="inherit">
                    Login
                  </Button>
                </Fragment>
              )}
            </Toolbar>
          </AppBar>
          <Routes childProps={childProps} />
        </StyledApp>
      )
    );
  }
}

const WithRouter = withRouter(App);

const WithProvider = () => (
  <ApolloProvider client={client}>
    <Rehydrated>
      <CssBaseline />
      <MuiThemeProvider theme={theme}>
        <WithRouter />
      </MuiThemeProvider>
    </Rehydrated>
  </ApolloProvider>
);

export default WithProvider;
