import gql from 'graphql-tag';

export default gql`
  query getNote($noteId: String!) {
    getNote(noteId: $noteId) {
      __typename
      noteId
      userId
      content
      attachment {
        region
        bucket
        key
      }
      createdAt
    }
  }
`;
