import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { TextField, FormLabel } from '@material-ui/core';
import LoaderButton from './LoaderButton';
import styled from 'styled-components/macro';

const StyledBillingForm = styled.form`
  .card-field {
    margin-bottom: 15px;
    background-color: white;
    padding: 11px 16px;
    border-radius: 6px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    line-height: 1.3333333;
  }
  .card-field.StripeElement--focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    border-color: #66afe9;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-group > * {
    margin-bottom: 5px;
  }
  .form-label {
    display: block;
  }
`

class BillingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      storage: '',
      isProcessing: false,
      isCardComplete: false,
    };
  }

  validateForm() {
    return this.state.name !== '' && this.state.storage !== '' && this.state.isCardComplete;
  }

  handleFieldChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleCardFieldChange = event => {
    this.setState({
      isCardComplete: event.complete,
    });
  };

  handleSubmitClick = async event => {
    event.preventDefault();

    const { name } = this.state;

    this.setState({ isProcessing: true });

    const { token, error } = await this.props.stripe.createToken({ name });

    this.setState({ isProcessing: false });

    this.props.onSubmit(this.state.storage, { token, error });
  };

  render() {
    const loading = this.state.isProcessing || this.props.loading;

    return (
      <StyledBillingForm className="BillingForm" onSubmit={this.handleSubmitClick}>
        <TextField
          fullWidth
          id="storage"
          className="form-group"
          label="Storage"
          helperText="Number of notes to store"
          type="number"
          inputProps={{ min: "0", step: "1" }}
          value={this.state.storage}
          onChange={this.handleFieldChange}
        />
        <TextField
          fullWidth
          id="name"
          className="form-group"
          label="Cardholder&apos;s name"
          placeholder="Name on the card"
          type="text"
          value={this.state.name}
          onChange={this.handleFieldChange}
        />
        <div className='form-group'>
          <FormLabel className='form-label'>Credit Card Info</FormLabel>
          <CardElement
            className="card-field"
            onChange={this.handleCardFieldChange}
            style={{
              base: { fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' },
            }}
          />
        </div>
        <LoaderButton
          className="wide-button"
          variant="contained"
          color="primary"
          size="large"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={loading}
          text="Purchase"
          loadingText="Purchasing…"
        />
      </StyledBillingForm>
    );
  }
}

export default injectStripe(BillingForm);
